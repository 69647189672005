import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { AppProgress, type IAppProgressOptions, type IAppProgressJSON } from 'o365.pwa.modules.client.steps.AppProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { babelParser, babelTraverse, nodeHtmlParser } from 'app-dependency-parser';
import { app, userSession } from 'o365-modules';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import AppResourceState from 'o365.pwa.modules.client.dexie.objectStores.AppResourceState.ts';
import { type SyncType } from "o365.pwa.types.ts";
import { importUtils } from 'o365-utils';

type ImportMaps = {
    [key: string]: ImportMap;
};

type ImportMap = {
    imports: { [key: string]: string };
    scopes: { [key: string]: { [key: string]: string } };
};

interface IFileOptions {
    appId: string;
    src: string;
    isModule?: boolean;
}

type MetaTags = {
    [key: string]: {
        [key: string]: HTMLElement;
    };
};

type OnBeforeSync = (memory: Object) => Promise<IOnBeforeSyncResult | null>;
type OnAfterSync = (memory: Object) => Promise<IOnAfterSyncResult | null>;

export interface IAppStepDefinitionOptions extends IStepDefinitionOptions {
    appId?: string;
    autoCloseDialogOnSuccess?: boolean;
    installingFromExternalApp?: boolean;
    extraResources?: Map<string, {
        isJSModule?: boolean;
    }>;
    entrypoint?: string;
    onBeforeSync?: OnBeforeSync;
    onAfterSync?: OnAfterSync;
}

export interface IOnBeforeSyncResult {
    error: Error;
    title: string;
    body: string;
}

export interface IOnAfterSyncResult {
    error: Error;
    title: string;
    body: string;
}

export class AppStepDefinition extends StepDefinition implements IOfflineStepDefinition<AppProgress>, IOnlineStepDefinition<AppProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';

    public appId: string;
    public installingFromExternalApp: boolean;

    public extraResources: Map<string, {
        isJSModule?: boolean
    }>;

    public autoCloseDialogOnSuccess?: boolean;

    public onBeforeSync?: OnBeforeSync;
    public onAfterSync?: OnAfterSync;
    public entrypoint?: string;

    private dependencies = new Map<string, AppResourceState>();
    private importMaps: ImportMaps = {};
    private metaTags: MetaTags = {};

    constructor(options: IAppStepDefinitionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'AppProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.AppProgress.vue');
            }
        });
        this.autoCloseDialogOnSuccess = options.autoCloseDialogOnSuccess;

        this.appId = options.appId ?? app.id;
        this.entrypoint = options.entrypoint;
        this.installingFromExternalApp = options.installingFromExternalApp ?? true;
        this.extraResources = options.extraResources ?? new Map();
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
    }

    updateOptions(options: IAppStepDefinitionOptions) {
        this.appId = options.appId ?? app.id;
        this.autoCloseDialogOnSuccess = options.autoCloseDialogOnSuccess;
        this.installingFromExternalApp = options.installingFromExternalApp ?? true;
        this.extraResources = options.extraResources ?? new Map();
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
    }

    generateStepProgress(options?: IAppProgressOptions | IAppProgressJSON, syncType?: SyncType): AppProgress {
        return new AppProgress(<IAppProgressOptions>{
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback,
        });
    }

    toRunStepDefinition() {
        return new AppStepDefinition({
            appId: this.appId,
            autoCloseDialogOnSuccess: this.autoCloseDialogOnSuccess,
            installingFromExternalApp: this.installingFromExternalApp,
            extraResources: this.extraResources,
            onBeforeSync: this.onBeforeSync,
            onAfterSync: this.onAfterSync,
            entrypoint: this.entrypoint,
            stepId: this.stepId,
            dependOnPreviousStep: this.dependOnPreviousStep,
            title: this.title
        });
    }

    async syncOffline(options: ISyncOptions<AppProgress>): Promise<void> {
        try {
            this.dependencies = new Map<string, AppResourceState>();
            this.importMaps = {};
            this.metaTags = {};

            const stepProgress = options.stepProgress;

            let promiseList = new Array<Promise<void>>();

            stepProgress.appsToDownload++;

            await this.parseApp(this.appId, stepProgress);

            for (const [extraResourceSrc, extraResourceOptions = { isJSModule: false }] of this.extraResources ?? new Map()) {
                promiseList.push(
                    this.resolveWebResource(extraResourceSrc, window.location.href, {
                        appId: this.appId,
                        src: extraResourceSrc,
                        isModule: extraResourceOptions.isJSModule
                    }, options.stepProgress)
                );
            }

            const promiseResults = await Promise.allSettled(promiseList);

            for (const promiseResult of promiseResults) {
                if (promiseResult.status === 'rejected') {
                    options.stepProgress.errors.push(promiseResult.reason);
                    options.stepProgress.webResourcesToDownload++;
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                }
            }

            const appRecord = await IndexedDBHandler.getApp(this.appId);

            if (appRecord === null) {
                throw new Error('Failed to find a valid app');
            }

            const serviceWorkerState = await appRecord.serviceWorkerState;

            if (serviceWorkerState === null) {
                throw new Error('Failed to find a valid service worker state');
            }

            const installedAppResourceStates = await serviceWorkerState.appResourceStates.getAll();

            for (const resource of installedAppResourceStates) {
                if (!this.dependencies.has(resource.primaryKey)) {
                    await resource.delete();
                }
            }

            for (const resource of this.dependencies.values()) {
                await resource.save();
            }

            options.syncProgress.requirePageReload = true;
        } catch (error: any) {
            options.stepProgress.errors.push(error);
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }

    async syncOnline(options: ISyncOptions<AppProgress>): Promise<void> {
        try {
            if (userSession?.personId !== 4178) {
                return;
            }
        } catch (error: any) {
            options.stepProgress.errors.push(error instanceof Error ? error : new Error(error.toString()));
            options.stepProgress.syncStatus = SyncStatus.SyncingCompleteWithErrors;
        }
    }

    private async parseApp(appId: string, stepProgress: AppProgress): Promise<void> {
        stepProgress.webResourcesToDownload++;

        let appResponse = await fetch(`https://${location.host}/nt/${appId}`, {
            headers: new Headers({
                'o365-workbox-strategy': 'O365-Offline-Sync',
                'X-O365-SKIP-CACHE-CHECK': 'true',
                'accept': 'text/html'
            })
        });

        if (appResponse.redirected) {
            appResponse = await fetch(appResponse.url, {
                headers: new Headers({
                    'o365-workbox-strategy': 'O365-Offline-Sync',
                    'X-O365-SKIP-CACHE-CHECK': 'true',
                    'accept': 'text/html'
                })
            });
        }

        stepProgress.webResourcesDownloaded++;

        const appHtmlText = await appResponse.text();

        const parser = new DOMParser();

        const parsedDocument = parser.parseFromString(appHtmlText, "text/html");

        const appImportMapNodes = parsedDocument.querySelectorAll('script[type=importmap-shim], script[type=importmap]');

        for (const appImportMapNode of appImportMapNodes) {
            const appImportMapText = appImportMapNode.textContent;
            const appImportMap = JSON.parse(appImportMapText ?? '{}');

            this.importMaps[appId] = appImportMap;
        }

        const parsedHtml: HTMLElement = nodeHtmlParser(appHtmlText, {
            comment: false
        });

        await this.handleNode(parsedHtml, appResponse.url, stepProgress, appId),

            await Promise.all([
                this.fetchAppDefinitions(appId, stepProgress),
                this.fetchUserSession(appId, stepProgress),
                this.fetchLocalSettings(stepProgress),
                this.fetchJsonSchemasSettings(stepProgress),
                this.fetchFileExtensionWhitelist(stepProgress)
            ]);

        stepProgress.appsDownloaded++;
    }

    private async handleNode(node: HTMLElement, url: string, stepProgress: AppProgress, appId: string): Promise<void> {
        switch (node.tagName) {
            case 'SCRIPT':
                await this.handleScriptNode(node, url, stepProgress, appId);
                return;
            case 'LINK':
                await this.handleLinkNode(node, url, stepProgress, appId);
                return;
            case 'META':
                await this.handleMetaNode(node, url, stepProgress, appId);
                return;
        }

        let promiseList = new Array<Promise<void>>();

        for (let i = 0; i < node.childNodes.length; i++) {
            const childNode = node.childNodes[i];

            if (childNode.childNodes) {
                promiseList.push(
                    this.handleNode(childNode as HTMLElement, url, stepProgress, appId)
                );
            }
        }

        let promiseResults = await Promise.allSettled(promiseList);

        for (const promiseResult of promiseResults) {
            if (promiseResult.status === 'rejected') {
                stepProgress.errors.push(promiseResult.reason);
                stepProgress.webResourcesFailedToDownload++;
                stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            }
        }
    }

    private async handleScriptNode(node: HTMLElement, url: string, stepProgress: AppProgress, appId: string): Promise<void> {
        let nodeType: string,
            nodeSrc: string | undefined;

        const attributes = node.attributes as NamedNodeMap | any;

        if (attributes instanceof NamedNodeMap) {
            nodeType = attributes.getNamedItem('type')?.value ?? 'classic-script';
            nodeSrc = attributes.getNamedItem('src')?.value;
        } else {
            nodeType = attributes['type'] ?? 'classic-script';
            nodeSrc = attributes['src'];
        }

        if (nodeSrc === undefined || nodeSrc.trim() === '') {
            return;
        }

        await this.resolveWebResource(nodeSrc, url, {
            appId: appId,
            src: nodeSrc,
            isModule: nodeType === 'module' || nodeType === 'module-shim'
        }, stepProgress);
    }

    private async handleLinkNode(node: HTMLElement, url: string, stepProgress: AppProgress, appId: string): Promise<void> {
        const attributes = node.attributes as NamedNodeMap | any;

        const nodeHref = ((): string | undefined => {
            if (attributes instanceof NamedNodeMap) {
                return attributes.getNamedItem('href')?.value;
            }

            return attributes['href'];
        })();

        if (nodeHref === undefined || nodeHref.trim() === '') {
            return;
        }

        const nodeRel = ((): string | undefined => {
            if (attributes instanceof NamedNodeMap) {
                return attributes.getNamedItem('rel')?.value;
            }

            return attributes['rel'];
        })();

        if (nodeRel === 'manifest') {
            return this.fetchManifest(nodeHref, stepProgress, appId);
        }

        await this.resolveWebResource(nodeHref, url, {
            appId: appId,
            src: nodeHref
        }, stepProgress);
    }

    private async handleMetaNode(node: HTMLElement, _url: string, _stepProgress: AppProgress, appId: string): Promise<void> {
        if (this.metaTags.hasOwnProperty(appId) === false) {
            this.metaTags[appId] = {};
        }

        const attributes = node.attributes as NamedNodeMap | any;

        let metaName: string | undefined;

        if (attributes instanceof NamedNodeMap) {
            metaName = attributes.getNamedItem('name')?.value;
        } else {
            metaName = attributes['name'];
        }

        if (typeof metaName === 'string') {
            this.metaTags[appId][metaName] = node;
        }
    }

    private async resolveWebResource(alias: string, relativeRoot: string, fileOptions: IFileOptions, stepProgress: AppProgress): Promise<void> {
        let sources: Map<string, Set<string>> = new Map();
        let isCdnProxyImport = false;

        getSources: {
            if (alias.startsWith('OMEGA365_CDN_PROXY')) {
                isCdnProxyImport = true;
                alias = alias.replace("OMEGA365_CDN_PROXY", "");
            }

            if (alias.startsWith('data:')) {
                return;
            } else if (alias.startsWith('/') || alias.startsWith('./') || alias.startsWith('../')) {
                let newUrl = new URL(alias, alias.startsWith('/nt/') ? window.location.href : relativeRoot);

                const relativeFilePath = newUrl.pathname;

                const relativeFilePathArray = relativeFilePath.split('/');

                relativeFilePathArray.splice(-1)

                const relativeFolder = relativeFilePathArray.join('/') + '/';

                const { imports, scopes } = this.importMaps[fileOptions.appId];

                const relativeFolderValue = scopes[relativeFolder];

                if (relativeFolderValue === undefined) {
                    sources.set(newUrl.href, new Set());

                    break getSources;
                }

                const relativeFileValue = imports[relativeFilePath] ?? relativeFolderValue[relativeFilePath];

                if (relativeFileValue === undefined) {
                    sources.set(newUrl.href, new Set());

                    break getSources;
                }

                newUrl = new URL(relativeFileValue, relativeFileValue.startsWith('/nt/') ? window.location.href : relativeRoot);

                sources.set(newUrl.href, new Set([relativeFolder]));

                break getSources;
            } else if (alias.startsWith('http://') || alias.startsWith('https://')) {
                sources.set(alias, new Set());

                break getSources;
            }

            const resolveRelativeUrl = (src: string, relativeRoot: string): string => {
                const isRelative = src.startsWith('/') || src.startsWith('./') || src.startsWith('../');

                if (!isRelative) {
                    return src;
                }

                if (src.startsWith('/nt/')) {
                    return new URL(src, window.location.href).href;
                }

                return new URL(src, relativeRoot).href;
            };

            let filePath: string | null = null;

            let correctedAlias = alias;

            if (correctedAlias.includes('/')) {
                filePath = correctedAlias.split('/').slice(1).join('/');
                correctedAlias = correctedAlias.split('/')[0] + '/';
            }

            const { imports, scopes } = this.importMaps[fileOptions.appId];


            if (imports.hasOwnProperty(correctedAlias) && typeof imports[correctedAlias] === 'string') {
                let newAlias = imports[correctedAlias];

                if (isCdnProxyImport) {
                    newAlias = `${location.origin}/cdn/${correctedAlias}${imports[correctedAlias].split(correctedAlias)[1]}`;
                }

                if (filePath) {
                    newAlias += filePath;
                }

                sources.set(resolveRelativeUrl(newAlias, relativeRoot), new Set());
            } else if (imports.hasOwnProperty(alias) && typeof imports[alias] === 'string' && alias.includes('/')) {
                let newAlias = imports[alias];

                sources.set(resolveRelativeUrl(newAlias, relativeRoot), new Set());
            }

            for (const [scope, scopeImports] of Object.entries(scopes)) {
                if (scopeImports.hasOwnProperty(correctedAlias) && typeof scopeImports[correctedAlias] === 'string') {
                    let newAlias = scopeImports[correctedAlias];

                    if (filePath) {
                        newAlias += filePath;
                    }

                    const url = resolveRelativeUrl(newAlias, relativeRoot);

                    if (!sources.has(url)) {
                        sources.set(url, new Set());
                    }

                    sources.get(url)?.add(scope);
                }
            }

            if (sources.size === 0) {
                let newAlias = correctedAlias;

                if (filePath) {
                    newAlias += filePath;
                }

                sources.set(resolveRelativeUrl(newAlias, relativeRoot), new Set());
            }
        }

        const promiseList = new Array<Promise<void>>();

        for (const [src, scopes] of sources.entries()) {
            const clonedFileOptions = Object.assign({}, fileOptions);

            clonedFileOptions.src = src;

            let shouldContinue = false;

            if (scopes.size === 0) {
                let appResourceState = new AppResourceState(this.appId, alias.trim(), '', relativeRoot, src.trim());

                if (this.dependencies.get(appResourceState.primaryKey)?.url === src) {
                    shouldContinue = true;
                } else {
                    this.dependencies.set(appResourceState.primaryKey, appResourceState);
                }
            }

            for (let scope of scopes) {
                let appResourceState = new AppResourceState(this.appId, alias.trim(), scope, relativeRoot, src.trim());

                if (this.dependencies.get(appResourceState.primaryKey)?.url === src) {
                    shouldContinue = true;
                } else {
                    this.dependencies.set(appResourceState.primaryKey, appResourceState);
                }
            }

            if (shouldContinue) {
                continue;
            }

            stepProgress.webResourcesToDownload++;

            promiseList.push(
                new Promise<void>(async (resolve, reject) => {
                    try {
                        const response = await fetch(src, {
                            method: "GET",
                            headers: new Headers({
                                'o365-workbox-strategy': 'O365-Offline-Sync'
                            }),
                        });

                        const fileContent = await response.text();

                        const srcUrl = new URL(src);
                        const srcPath = srcUrl.pathname;

                        if (srcPath.endsWith('.js') || srcPath.endsWith('.ts') || srcPath.endsWith('.vue') || srcPath.endsWith('.jsx') || srcPath.endsWith('.tsx')) {
                            await this.resolveJsDependencies(fileContent, clonedFileOptions, stepProgress);
                        } else if (srcPath.endsWith('.css')) {
                            await this.resolveCssDependencies(fileContent, clonedFileOptions, stepProgress);
                        }

                        stepProgress.webResourcesDownloaded++;

                        resolve();
                    } catch (reason) {
                        reject({ src, reason });
                    }
                })
            );
        }

        let promiseResults = await Promise.allSettled(promiseList);

        for (const promiseResult of promiseResults) {
            if (promiseResult.status === 'rejected') {
                stepProgress.errors.push(promiseResult.reason);
                stepProgress.webResourcesFailedToDownload++;
                stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            }
        }
    }

    private async resolveJsDependencies(fileContent: string, fileOptions: IFileOptions, stepProgress: AppProgress): Promise<void> {
        const ast = babelParser.parse(fileContent, { sourceType: fileOptions.isModule ? 'module' : 'script' });

        const subDependencies = new Array<string>();

        babelTraverse(ast, {
            ImportDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            ExportNamedDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            ExportAllDeclaration: this.resolveJsImportDeclaration.bind(this, subDependencies),
            CallExpression: this.resolveJsCallExpression.bind(this, subDependencies)
        });

        let promiseList = new Array<Promise<void>>();

        for (let i = 0; i < subDependencies.length; i++) {
            const subDependency = subDependencies[i];

            promiseList.push(
                this.resolveWebResource(subDependency, fileOptions.src, {
                    appId: fileOptions.appId,
                    src: subDependency,
                    isModule: true
                }, stepProgress)
            );
        }

        let promiseResults = await Promise.allSettled(promiseList);

        for (const promiseResult of promiseResults) {
            if (promiseResult.status === 'rejected') {
                stepProgress.errors.push(promiseResult.reason);
                stepProgress.webResourcesFailedToDownload++;
                stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            }
        }
    }

    private async resolveCssDependencies(fileContent: string, fileOptions: IFileOptions, stepProgress: AppProgress): Promise<void> {
        const urlRegex = /url\(\s*(['"]?)(?!#)([^'"\)]+)\1\s*\)/g;

        const urlMatches = [...fileContent.matchAll(urlRegex)].map(match => {
            const url = match[2];

            const startsWithHttp = url.startsWith('http');
            const startsWithRelativeUrl = url.startsWith('/') || url.startsWith('./') || url.startsWith('../');
            const dataUrl = url.startsWith('data:');

            if (startsWithHttp || startsWithRelativeUrl || dataUrl) {
                return url;
            }

            return `./${url}`;
        }).filter((url) => {
            return !url.startsWith('data:');
        });

        let promiseList = new Array<Promise<void>>();

        for (let i = 0; i < urlMatches.length; i++) {
            const subDependency = urlMatches[i];

            promiseList.push(
                this.resolveWebResource(subDependency, fileOptions.src, {
                    appId: fileOptions.appId,
                    src: subDependency
                }, stepProgress)
            );
        }

        let promiseResults = await Promise.allSettled(promiseList);

        for (const promiseResult of promiseResults) {
            if (promiseResult.status === 'rejected') {
                stepProgress.errors.push(promiseResult.reason);
                stepProgress.webResourcesFailedToDownload++;
                stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            }
        }
    }

    private resolveJsImportDeclaration(subDependencies: Array<string>, path: any): void {
        const sourceValue = path?.node?.source?.value;

        if ([undefined, null, ''].includes(sourceValue?.trim())) {
            return;
        }

        subDependencies.push(sourceValue);
    }

    private resolveJsCallExpression(subDependencies: Array<string>, path: any): void {
        const node = path.node;
        const nodeArguments = node.arguments;
        const callee = node.callee;

        if ([undefined, null].includes(nodeArguments) || [undefined, null].includes(callee)) {
            return;
        }

        const calleeType = callee.type;

        const isImport = calleeType === 'Import' && nodeArguments.length === 1 && nodeArguments[0].type === 'StringLiteral';
        const isCdnProxyImport = 'getCdnProxyUrl' === callee.name && nodeArguments.length === 1 && nodeArguments[0].type === 'StringLiteral';
        const isO365ImportFunction = [
            'getLibUrl',
            'loadStyle',
            'loadCdnStyle',
            'getTemplate',
            'loadScript',
            'defineAsyncComponent',
            'useAsyncComponent'
        ].includes(callee.name) && nodeArguments.length === 1 && nodeArguments[0].type === 'StringLiteral';

        if (isImport || isO365ImportFunction) {
            subDependencies.push(nodeArguments[0].value);
        } else if (isCdnProxyImport) {
            subDependencies.push("OMEGA365_CDN_PROXY" + nodeArguments[0].value);
        }
    }

    private async fetchAppDefinitions(appId: string, stepProgress: AppProgress): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            const metaTags = this.metaTags[appId];
            const dbobjectdefinitionFingerPrint = metaTags['o365-dbobjectdefinition-fingerprint'].getAttribute('content');
            const appFingerPrint = metaTags['o365-app-fingerprint'].getAttribute('content');

            const fingerPrint = (dbobjectdefinitionFingerPrint ?? '') > (appFingerPrint ?? '') ? dbobjectdefinitionFingerPrint : appFingerPrint;


            await fetch(`/nt/api/apps/${appId}.${fingerPrint}.json`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }

        const promiseList = new Array<Promise<void>>();

        const metaTags = this.metaTags[appId];

        for (const [metaTagName, metaTagNode] of Object.entries(metaTags)) {
            if (!metaTagName.startsWith('o365-app-dependency-fingerprint-')) {
                continue;
            }

            promiseList.push(new Promise(async (resolve, reject) => {
                try {
                    stepProgress.webResourcesToDownload++;

                    const dependencyName = metaTagName.replace('o365-app-dependency-fingerprint-', '');

                    const fingerprint = metaTagNode.getAttribute('content');

                    await fetch(`/nt/api/apps/${dependencyName}.${fingerprint}.json`, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'o365-workbox-strategy': 'O365-Offline-Sync'
                        }
                    });

                    stepProgress.webResourcesDownloaded++;

                    resolve();
                } catch (reason) {
                    reject(reason);
                }
            }));
        }

        let promiseResults = await Promise.allSettled(promiseList);

        for (const promiseResult of promiseResults) {
            if (promiseResult.status === 'rejected') {
                stepProgress.errors.push(promiseResult.reason);
                stepProgress.webResourcesFailedToDownload++;
                stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            }
        }
    }

    private async fetchUserSession(appId: string, stepProgress: AppProgress): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            const metaTags = this.metaTags[appId];
            const usersessionFingerPrint = metaTags['o365-person-lastloggedin'].getAttribute('content');

            await fetch(`/nt/api/usersession.${usersessionFingerPrint}.json`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }
    }

    private async fetchManifest(href: string, stepProgress: AppProgress, appId: string): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            const manifestResponse = await fetch(href, {
                method: 'GET',
                headers: {
                    'Accept': 'application/manifest+json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            const body = await manifestResponse.text();

            const manifest = JSON.parse(body);

            let promiseList = new Array<Promise<void>>();

            const icons = manifest.icons;

            for (const icon of icons) {
                promiseList.push(
                    this.resolveWebResource(icon.src, manifestResponse.url, {
                        appId: appId,
                        src: icon.src
                    }, stepProgress)
                );
            }

            const screenshots = manifest.screenshots;

            for (const screenshot of screenshots) {
                promiseList.push(
                    this.resolveWebResource(screenshot.src, manifestResponse.url, {
                        appId: appId,
                        src: screenshot.src
                    }, stepProgress)
                );
            }

            let promiseResults = await Promise.allSettled(promiseList);

            for (const promiseResult of promiseResults) {
                if (promiseResult.status === 'rejected') {
                    stepProgress.errors.push(promiseResult.reason);
                    stepProgress.webResourcesFailedToDownload++;
                    stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                }
            }

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }
    }

    private async fetchLocalSettings(stepProgress: AppProgress): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            await fetch(importUtils.getLibUrl("local.settings.json"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }
    }

    private async fetchJsonSchemasSettings(stepProgress: AppProgress): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            await fetch(importUtils.getLibUrl("o365.jsonSchemas.settings.json"), {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }
    }

    private async fetchFileExtensionWhitelist(stepProgress: AppProgress): Promise<void> {
        try {
            stepProgress.webResourcesToDownload++;

            await fetch("/api/file/get-extension-whitelist", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'o365-workbox-strategy': 'O365-Offline-Sync'
                }
            });

            stepProgress.webResourcesDownloaded++;
        } catch (reason) {
            stepProgress.webResourcesFailedToDownload++;

            throw reason;
        }
    }
}
